<template>
    <div id="dom" class="body">
      <header>
        <img class="navImg" src="@/assets/img/u0.jpg">
        <div class="navRight">
          <div style="display:flex">
            <img src="@/assets/img/u8.svg" alt="">
            <img src="@/assets/img/u9.svg" alt="">
          </div>
          <div>185-0716-9976</div>
        </div>
      </header>
      <section class="navs">
        <ul>
          <router-link to="/home"><li style="margin-left: 0;" :class="active === 1?'active':''">首页</li></router-link>
          <router-link to="/product"><li :class="active === 2?'active':''">产品</li></router-link>
          <router-link to="/case"><li :class="active === 3?'active':''">案例</li></router-link>
          <!-- <li :class="active === 4?'active':''">资源</li>
          <li :class="active === 5?'active':''">招聘</li>
          <li :class="active === 6?'active':''" style="position: absolute;right:20px;width:100px">关于我们</li> -->
        </ul>
      </section>
      <main>
        <section style="margin-bottom: 10px">
          <el-carousel :interval="5000" arrow="always" height="150px">
            <el-carousel-item v-for="(item,index) in imgList" :key="index">
              <img style="width:100%" :src="item.img" />
            </el-carousel-item>
          </el-carousel>
        </section>
        <div style="border-bottom: 4px solid rgba(215, 215, 215, 1);padding-bottom: 5px;margin-bottom:10px">
            <el-button type="warning">经典案例介绍</el-button>
        </div>
        <el-tabs v-model="activeName">
            <el-tab-pane name="1">
                <span slot="label"><img style="height:50px" src="@/assets/img/u167.png"></span>
                <section style="display:flex">
                    <div class="linknav">
                        <div style="font-size:22px;line-height:40px;font-weight:bold">东风汽车股份新能源事业部CRM营销管理系统</div>
                        <p>
                            东风汽车股份有限公司（中文简称“东风汽车股份”，英文简称“DFAC”，沪市上市代码600006）是经国家证监会证监发行字［1999］68号文批准，
                            由东风公司独家发起，采取公开募集方式于1999年7月15日创立，于1999年7月27日在上海证券交易所上市交易。
                        </p>
                        <p>
                            东风汽车股份主营业务发展到轻卡、客车、工程车、皮卡等产品平台；在发展传统燃油汽车的同时，大力发展纯电动等新能源汽车，
                            多方位满足客户的差异化需求。
                        </p>
                    </div>
                    <img style="height: 160px;" src="@/assets/img/u168.png">
                </section>
                <section style="display:flex">
                    <img  src="@/assets/img/u170.svg">
                    <div class="linknav">
                        <p style="margin :100px 10px 0">
                            新能源事业部作为东风汽车股份新能源汽车板块，销售业务形态有大客户销售、经销商网络销售、专用车销售等。2020年统筹规划新能源事业部营销领域信息化建设蓝图，
                            并实施了销售管理。实现模块包括采购订单、销售订单、财务管理、仓储管理等，同财务SAP、生产MES、供应链SCF系统数据交换。
                        </p>
                    </div>
                </section>
                <p style="margin: 10px 0;font-size: 14px;text-indent:2em;letter-spacing:2px;">
                    项目实施业务范围涵盖新能源事业部大客户销售业务、常网建立及销售业务、专用车销售业务、改装业务、财务相关业务、储运业务等，系统能适应销售业务不断变化，
                    在项目实施后可快速应对业务部门优化和调整需求。项目实施功能包括计划管理、采购管理、合同管理、集团大客户管理、改装管理、储运管理、入出存管理、
                    店端销售管理、社会库存管理、财务管理、发票管理、网络管理、主数据管理、报表管理、接口管理等模块。
                </p>
                <section>
                    <img style="width:100%" src="@/assets/img/u173.svg">
                    <img style="width:100%" src="@/assets/img/u175.svg">   
                </section>
            </el-tab-pane>
            <el-tab-pane name="2">
                <span slot="label"><img style="height:50px" src="@/assets/img/u177.png"> </span>
                <section style="display:flex">
                    <img style="height: 190px;" src="@/assets/img/u206.png">
                    <div class="linknav">
                        <div style="font-size:22px;line-height:22px;font-weight:bold">程力汽车集团运营管理系统</div>
                        <p>
                            湖北程力集团总部位于中国专用汽车之都湖北省随州市，是集汽车技术研发、汽车制造、汽车改装、汽车零部件、金融、教育、置业投资为一体的多元化集团公司。
                            程力集团下属核心企业有程力专用汽车股份有限公司、湖北大力专用汽车制造有限公司、程力重工股份有限公司、程力新能源汽车制造股份有限公司、湖北程力置
                            业投资有限公司、随州市经济开发区程力小额贷款有限公司、程力职业技术学院等组成
                        </p>
                        <p>
                            集团是一家拥有“中国驰名商标”“湖北名牌”“国家高新技术企业”“中国民企500强”的大型汽车制造集团，是集汽车技术研发、汽车制造、客车制造、专用汽车改装、
                            汽车零部件、金融、教育、置业投资为一体的多元化集团公司
                        </p>
                    </div>  
                </section>
                <p style="margin: 5px 0;font-size: 14px;text-indent:2em;letter-spacing:2px;">
                    2021年程力集团与力源达签署程力集团运营管理系统战略合作框架协议，双方抽调业务骨干组成项目组。经过2个月深入程力集团各职能部门，三大核心板块沉入式业务调研，
                    规划形成程力集团未来信息化建设蓝图。系统规划涵盖整车及底盘采购管理、生产管理、销售管理等。通过DEP数据交换平台实现同内部金蝶系统数据集成。本项目充分展示
                    出力源达两大产品（EDP快速开发平台和DEP数据交换平台）的优势，为达成规划目标起到重要作用。
                </p>
                <img style="width:100%" src="@/assets/img/u207.svg">
                <p style="margin: 5px 0;font-size: 14px;text-indent:2em;letter-spacing:2px;">
                    项目实施业务范围涵盖程力集团三大核心板块，业务领域包括销售管理、财务管理、生产管理、辅助业务管理。销售管理包括整车销售、底盘销售、半成品销售等。
                    财务管理包括应收管理、应付管理、资金管理等。生产管理包括采购管理、仓库管理、生产过程管理、半成品管理、成品管理等。辅助业务管理包括公告管理、
                    售后服务管理、物业管理等。
                </p>
                <p style="margin: 5px 0;font-size: 14px;text-indent:2em;letter-spacing:2px;">
                    系统建设包括PC端功能和移动端手机功能，实现钉钉办公app与运营管理系统移动功能集成。
                </p>
                <img style="width:100%"  src="@/assets/img/u208.svg">
                <img style="width:100%"  src="@/assets/img/u211.svg">
                <div style="display:flex;justify-content: space-around;padding: 30px 0">
                    <img  src="@/assets/img/u209.svg">
                    <img  src="@/assets/img/u210.svg">
                </div>
            </el-tab-pane>
        </el-tabs>
      </main>
      <footer>
        <div class="text">相互相成 真心服务</div>
        <img src="@/assets/img/u23.png" />
        <div>
          <span style="text-align: left;">关键字：低代码 配置化 开发工具 开发平台 数据交换 可视化 异构数据库交换平台 企业管理软件</span>
          <span style="text-align: right;">武汉力源达信息技术有限公司</span>
          <span style="text-align: center;font-size: 10px;line-height: 20px;">copyright © 武汉力源达信息技术有限公司版权所有 | ICP主体备案号：鄂ICP备19030060号 | 备案状态：正常 </span>
        </div>
      </footer>
      <section style="position: fixed;right: 5%;top:40%;">
        <suspension  />
      </section>
      <el-button   type="info" icon="el-icon-caret-top" 
                  circle class="backTop" 
                  v-show="flag_scroll" @click="backTop()"></el-button>
    </div>
</template>

<script>
import suspension from '@/components/common/suspension'
export default {
    name:"", 
    components: {
        suspension
    },
    mounted() {
      //滚动监听
      window.addEventListener('scroll', this.handleScroll, true)
    },
    //销毁滚动监听
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    methods: {
      //置顶
        backTop(){
            document.getElementById('dom').scrollIntoView()
        },
        //监听滚动事件
        handleScroll(){
            if(document.body.scrollTop > 500){
                this.flag_scroll = true
            }else {
                this.flag_scroll = false
            }
        },
    },
    data() {
        return {
            active:3,
            activeName:'1',
            flag_scroll: false,
            imgList: [
            {
                img:require('@/assets/img/u28.gif'),
            },
            {
                img:require('@/assets/img/u30.png'),
            },
            {
                img:require('@/assets/img/u31.png'),
            }, 
            ]
        }
    },
}
</script>

<style scoped lang="less">
.body {
    margin: 0px;
    background-image: none;
    position: relative;
    left: 0px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
header {
  position: relative;
  display: flex;
  .navImg {
    width: 100%;
  }
  .navRight {
    position: absolute;
    right: 0;
    img {
      margin-right: 30px;
    }
  }
}
footer {
    position: relative;
    width: 100%;
    // height: 200px;
    .text {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translate(-50%,0);
        font-size: 35px;
        text-align: center;
        margin: 0 auto;
        width: 300px;
        background: rgba(215, 215, 215, 0);
    }
    img {
      width: 100%;
    }
    div {
      display: flex;
      flex-direction: column;
      background: rgba(215, 215, 215, 1);
      margin-top: -5px;
      padding: 0 20px;
      span {
        line-height: 40px;
      }
    }
}
main {
    .linknav {
        flex: 1;
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        position: relative;
        p {
            font-size: 14px;
            text-indent:2em;
            letter-spacing:2px;
            margin: 5px 0;
        }
        
    }
    
}
.navs {
    width: 100%;
    background: rgba(0, 147, 208, 1);
    ul {
      display: flex;
      list-style: none;
      height: 45px;
      line-height: 45px;
      margin-bottom: 5px;
      color: #fff;
      position: relative;
      li {  
        width: 60px;
        margin-left: 70px;
        text-align: center;
        cursor: pointer;
        &:hover {
          width: 60px;
          margin-top: 2.5px;
          height: 40px;
          line-height: 40px;
          background: #fff;
          border-radius: 5px;
          font-size: 20px;
          color: rgba(0, 147, 208, 1);
        }
      }
    }
}
.active {
    width: 60px;
    margin-top: 2.5px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    border-radius: 5px;
    font-size: 20px;
    color: #0093d0;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    // line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.imageList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  img {
    width: 100%;
  }
}

a {
    color: #fff!important;
    text-decoration: none!important;
}
 
.router-link-active {
    color: #fff!important;
    text-decoration: none!important;
}
.backTop {
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 99;
}

/deep/.el-tabs__nav-wrap::after {
    position: static !important;
}
// /deep/.el-tabs__active-bar {
//   background-color: transparent !important;
// }
</style>
